import React from 'react'
import UserDetailsComponent from '../../components/cards/misc/user-Details'

const UserDetails = ({location}) => {
    const params = new URLSearchParams(location.search);
    const parameter1 = params.get("email");
    const parameter2 = params.get("category");
  return (
   <>
    <UserDetailsComponent 
    email={parameter1}
    category={parameter2}
    />
   </>
  )
}

export default UserDetails