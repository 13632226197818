import { Grid } from "@mui/material";
import { navigate } from "gatsby-link";
import React, { useState } from "react";
import { sendMiscDetailsHandler } from "../../../actions/user/action";
import Toaster from "../../common/Toaster";
import "./styles.scss";
import { connect } from "react-redux";

const UserDetailsComponent = ({ email,category,sendMiscDetailsHandler }) => {
  const navigateToSignup = () => {
    navigate("/onboarding/step-one");
  };
  
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");

  const [business,setBusiness] = useState("")
  const [targetMarket,setTargetMarket] = useState("")
  const [interest,setInterest] = useState("")
  const [name,setname] = useState("")

  const submitHandler = async () =>{
    if(business==='' || business==='Others'){
        setToastrMsg("Business Definition is required");
        doRefresh((prev) => prev + 1);
        return;
    }
    else if(targetMarket===''){
        setToastrMsg("Target Market is required");
        doRefresh((prev) => prev + 1);
        return;
    }
    else if(interest===''){
        setToastrMsg("Interest is required");
        doRefresh((prev) => prev + 1);
        return;
    }

    var obj={
        html:` You have a Client form.<br><br> 
        <h3>Details are</h3>
        <ul style="list-style-type:none;">
        <li>Email: ${email}<br></li>
        <li>Business: ${business}<br></li>
        <li>Target Market: ${targetMarket}<br></li>
        <li>Interests: ${interest}<br></li>
        <li>Name: ${name}<br></li>
        </ul>`,
        subject:'Client form (with business details)'
      }

      var clientEmail = {
        html:` Hi ${name}

        I'm Mike from <a href="http://avmade.com">AVendors</a> apparel factory. Thanks for checking us out. It was great to learn you're in the ${targetMarket} apparel market. What are some of your short-term needs and how can we help? <br><br>
        
        Btw, here is also a link to learn <a href="https://www.dropbox.com/s/jlrttslpr95skrb/About%20Us%20%28AVendors%29.pdf?dl=0">about us</a> and what we do for ${business}s behind the scenes. <br><br>
        
        Chat soon,<br><br>
        
        Mike Cherent<br>
        Brands Manager<br>
        Mike@avendors.com<br>
        213 434 5420`,
        subject:'Mike from AVendors apparel Factory'
      }

    var submitObject = {
        email:email,
        business:business,
        targetMarket:targetMarket,
        interest:interest,
        name:name,
        clientEmail:clientEmail,
        emailBody:obj
    }
    
    var sendMiscDetails = await sendMiscDetailsHandler(submitObject);
        if (sendMiscDetails && !sendMiscDetails.error) {
          navigate(`/products/collections/${category}`);
        } else {
          
          setToastrMsg("Error Occurred!!");
          doRefresh((prev) => prev + 1);
        }


    // console.log(submitObject)
  }


  return (
    <>
     <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="misc-user-details">
        <div className="header">
          <img src="/images/common/plus_new_logo.svg" onClick={()=>navigate("/")}/>
          <div className="main-header__item item2">
            <button type="button" onClick={navigateToSignup}>
              Sign up
            </button>
          </div>
        </div>
        <div className="main-section">
          <div className="main-section__title">What defines your business?</div>
          <div className="main-section-items">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="main-section-grid"
          >
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${business==='Brand' && 'highlight'}`} onClick={()=>setBusiness("Brand")}></div> <span>Brand</span>
            </Grid>
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${business==='Boutiqe' && 'highlight'}`} onClick={()=>setBusiness("Boutiqe")}></div> <span>Boutiqe</span>
            </Grid>
            <br />
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${business==='Influencer' && 'highlight'}`} onClick={()=>setBusiness("Influencer")}></div> <span>Influencer</span>
            </Grid>
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${business==='Corporate' && 'highlight'}`} onClick={()=>setBusiness("Corporate")}></div> <span>Corporate</span>
            </Grid>
            <Grid item xs={12} md={6} className="grid-item">
            <div className={`empty-box ${(business!=='' && business!=='Brand' && business!=='Boutiqe' && business!=='Influencer' && business!=='Corporate') && 'highlight'}`}
            onClick={()=>setBusiness("Others")}
            ></div > <input 
            placeholder="Others (type here)"
            onChange={(e)=>setBusiness(e.target.value)}
            value={(business!=='Brand' && business!=='Boutiqe' && business!=='Influencer' && business!=='Corporate' && business!=='Others') ? business : ''}
            ></input>
            </Grid>
          </Grid>
          </div>

          <div className="main-section__title mt-main-title">What’s your target market?</div>
          <div className="main-section-items">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="main-section-grid"
          >
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${targetMarket==='Eco-friendly' && 'highlight'}`} onClick={()=>setTargetMarket("Eco-friendly")}></div> <span>Eco-friendly</span>
            </Grid>
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${targetMarket==='Contemporary' && 'highlight'}`} onClick={()=>setTargetMarket("Contemporary")}></div> <span>Contemporary</span>
            </Grid>
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${targetMarket==='Lounge' && 'highlight'}`} onClick={()=>setTargetMarket("Lounge")}></div> <span>Lounge</span>
            </Grid>
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${targetMarket==='Athleisure' && 'highlight'}`} onClick={()=>setTargetMarket("Athleisure")}></div> <span>Athleisure</span>
            </Grid>
            <Grid item xs={6} md={3} className="grid-item">
            <div className={`empty-box ${targetMarket==='Hi-Street' && 'highlight'}`} onClick={()=>setTargetMarket("Hi-Street")}></div> <span>Hi-Street</span>
            </Grid>
            
          </Grid>
          </div>

          <div className="main-section__title mt-main-title">What are you interested in?</div>
          <div className="main-section-items">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="main-section-grid"
          >
            <Grid item xs={12} md={12} className="grid-item">
            <div className={`empty-box ${interest==='Custom' && 'highlight'}`} onClick={()=>setInterest("Custom")}></div> <span>Luxury basics to customize</span>
            </Grid>
            <Grid item xs={12} md={12} className="grid-item">
            <div className={`empty-box ${interest==='Stock' && 'highlight'}`} onClick={()=>setInterest("Stock")}></div> <span>Luxury basics to buy as stock</span>
            </Grid>
          </Grid>
          </div>

          <div className="main-section__title mt-main-title">What is your Full Name?</div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="main-section-grid"
          >
            <Grid item xs={12} md={12} className="grid-item">
            <input 
            placeholder="type here"
            onChange={(e)=>setname(e.target.value)}
            className="name-input"
            value={ name}
            ></input>
            </Grid>
           
          </Grid>

            <div className="access-div">
            <button className="access-button" onClick={submitHandler}>Access Products</button>
            </div>
         
        </div>
      </div>
    </>
  );
};

// export default UserDetailsComponent;
const mapStateToProps = (state) => ({
    state: state.products,
  });
  
  export default connect(mapStateToProps, {
    sendMiscDetailsHandler
  })(UserDetailsComponent);
