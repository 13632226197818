import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPurchaseDataDetailAction } from "../../../actions/product/action";
import Loader from "../../common/Loader";
import Toaster from "../../common/Toaster";
//importing styles
import "./styles.scss";
import { navigate } from "gatsby";

const OrderComponent = ({ state, getPurchaseDataDetailAction, id }) => {
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [purchase, setPurchase] = useState({});

  useEffect(async () => {
    setLoading(true);
    let uId = localStorage.getItem("userId");

    if (!uId || uId === null) {
      navigate("/onboarding/step-one");
    } else {
      let response = await getPurchaseDataDetailAction(
        localStorage.getItem("userId"),
        id
      );

      setLoading(false);
      if (response.error) {
        if (response.errorData === "Please authenticate") {
          setToastrMsg("Your session has expired. please login again!");
          doRefresh((prev) => prev + 1);
          setTimeout(() => {
            localStorage.clear();
            navigate("/login");
          }, 500);
        }
      } else {
        if (
          response &&
          response.responseData.success &&
          response.responseData.data &&
          response.responseData.data.cart &&
          response.responseData.data.cart.purchases &&
          response.responseData.data.cart.purchases.length >= 0
        ) {
          setPurchase(response.responseData.data.cart.purchases[0]);
          setOrders(response.responseData.data.cart.purchases[0].orders);
        } else {
          setToastrMsg("Orders not found!");
          doRefresh((prev) => prev + 1);
          // navigate("/");
        }

        // setToastrMsg(response.errorData);
        // doRefresh((prev) => prev + 1);
      }
    }
  }, []);

  const navigateHandler = (order) => {
    if (order && (!order.others || (order.others && order.others == ""))) {
      navigate(`/product/${order.productUniqueId}`);
    }
    if (order && (order && order.others === "STOCK")) {
      navigate(`/product/stock/${order.productUniqueId}`);
    } else if (order && order.others === "SWATCH") {
      navigate(`/`);
    } else if (order && order.others === "BOOK") {
      navigate(`/products/pantone-book`);
    }
  };

  return (
    <>
      <Loader isLoading={loading} />
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="orders-detail-component">
        <div className="orders-title">Order Details</div>
        {/* <div className="orders-sub-title">Starting from most recent</div> */}
        <div className="orders-sub-title-ul"></div>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="orders-grid"
        >
          {orders.map((order) => (
            <Fragment key={order._id}>
              <Grid item xs={12} md={4} className="order-item">
                <div className="order-container">
                  <div className="order-image">
                    {order &&
                      (!order.others ||
                        (order.others && order.others == "")) && (
                        <img
                          src={
                            order.product &&
                            order.product.images &&
                            order.product.images.length > 0 &&
                            order.product.images[0].image
                          }
                        />
                      )}
                    {order && order.others === "SWATCH" && (
                      <img src="/images/common/Swatch Image.jpg" />
                    )}
                    {order && order.others === "BOOK" && (
                      <img src="/images/common/pantones/1.webp" />
                    )}
                  </div>
                  <div className="order-number">
                    Style# :{" "}
                    <span>
                      {(order && order.others === "BOOK") ? "COLOR BOOK" :order.product.internalId}
                    </span>
                  </div>
                  <button
                    className="view-more-button"
                    onClick={() => navigateHandler(order)}
                  >
                    Order More
                  </button>
                  {/* <div className="order-number m-center"> */}
                  {/* Quantity : <span>{order.totalQuantity}</span> <br />
                    Price : <span>{order.totalPrice}</span> */}
                  {/* <span>{order.product.internalId}</span>
                    <button>Order More</button> */}
                  {/* </div> */}
                </div>
              </Grid>
            </Fragment>
          ))}
        </Grid>

        {/* <div className="orders-title">Shipping Details</div>
        <div className="orders-sub-title">Starting from most recent</div>
        <div className="orders-sub-title-ul"></div>

        <div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="orders-grid"
          >
            <Grid item xs={12} md={6} className="order-item">
             {purchase.shippingAddressDetails && <div className="shipping">
                <span className="item-title-address">Shipping Address: <br /></span>
                {purchase.shippingAddressDetails.address}<br />
                {purchase.shippingAddressDetails.city}, {purchase.shippingAddressDetails.state}<br />
                USA, {purchase.shippingAddressDetails.zip}
              </div>}
            </Grid>
            <Grid item xs={12} md={6} className="order-item">
             {purchase.billingAddressDetails &&  <div className="billing">
             <span className="item-title-address"> Billing Address: <br /> </span>
                {purchase.billingAddressDetails.address}<br />
                {purchase.billingAddressDetails.city}, {purchase.billingAddressDetails.state}<br />
                USA, {purchase.billingAddressDetails.zip}
              </div>}
            </Grid>
          </Grid>
        </div> */}

        <div className="download-invoice-container">
          {/* <a href='/somefile.txt' download>Click to download</a> */}
          <a href={purchase.invoice} download className="download-invoice">
            Download Invoice
            <img
              className={`add-dd expanded`}
              src="/images/common/addon-dropdown.png"
            />
          </a>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state.data.userDetails,
});

export default connect(mapStateToProps, {
  getPurchaseDataDetailAction,
})(OrderComponent);
