import React from 'react';
import OrdersIndex from '../../components/cards/orderDetails';


const OrderDetails = ({id}) => {
  return (
    <>
    <OrdersIndex 
    id={id}
    /> 
    </>
  )
}

export default OrderDetails