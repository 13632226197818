import React from "react";
import { Router, Link, Location } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./main.css";
import Collections from "./products/collections";
import Product from "./product";
import SetPassword from "./onboarding/set-password";
import { LiveChatWidget } from "@livechat/widget-react";
import Profile from "./profile";
import UserDetails from "./misc/user-details";
import OrderDetails from "./orders/orderDetails";
import NotFoundPage from "./404";
import Seo from "./Seo";
import { common } from "../util/constants";
// import Head from "./Head";

export const Head = ({ location, params, data, pageContext }) => {
  return (
    <>
     <Seo 
     location={`${common.APP_FE_URL}${location.pathname}`}
     params={params}
     pageContext={pageContext}
      />
    </>
  );
};

const App = () => (
  <>
      <div className="app">
        <FadeTransitionRouter>
          <Collections path="/" />
          <Collections path="products/collections/:id" />
          <Product path="product/:productId/*" />
          <Product path="product/:productId/:modifyId/*" />
          <Product path="products/product/:productId/*" />
          <Product path="products/product/:productId/:modifyId/*" />
          <SetPassword path="onboarding/set-password/:token" />
          <Profile path="profile/:profileId" />
          <OrderDetails path="order/:id" />
          {/* <UserDetails path="/misc/user-details?email" /> */}
          <NotFoundPage path="*" />
        </FadeTransitionRouter>
        {/* <LiveChatWidget license="14411064" visibility="minimized" /> */}
      </div>
  </>
);

const FadeTransitionRouter = (props) => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          {/* the only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location" */}
          <Router location={location} className="router">
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

export default App;
