import React from 'react'
import LeftDrawerComponent from '../../common/leftDrawer'
import OrderComponent from './OrderComponent'


const OrderDetailsIndex = ({id}) => {
  return (
    
    <div className='left-container'>
       <OrderComponent 
       id={id}
        />
       <LeftDrawerComponent />
      </div>
  )
}

export default OrderDetailsIndex